import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { useCredits } from './CreditsContext';
import { db } from './firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { generateAffiliateCode } from './utils/affiliateCode';
import { FaCopy } from 'react-icons/fa';
import Header2 from './components/Header2';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import stripeLogo from './assets/stripelogo.png'; // Importing the Stripe logo

const domain = process.env.REACT_APP_DOMAIN;
console.log('Domain:', domain);
axios.defaults.baseURL = process.env.REACT_APP_DOMAIN;
// tail
function MyCreditsPage() {
    const { currentUser } = useAuth();
    const { credits, setCredits } = useCredits();
    const [affiliateLink, setAffiliateLink] = useState('');
    const [loading, setLoading] = useState(true);
    const [creditPackages, setCreditPackages] = useState([]);
    const [isUS, setIsUS] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState({});
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUserData = async () => {
            if (currentUser) {
                const userDoc = doc(db, 'ep', currentUser.uid);
                const userSnap = await getDoc(userDoc);
                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    setCredits(userData.credits);
                    const affiliateCode = generateAffiliateCode(currentUser.uid);
                    setAffiliateLink(`${window.location.origin}/signup?ref=${affiliateCode}`);
                }
                setLoading(false);
            }
        };

        fetchUserData();
    }, [currentUser, setCredits]);

    useEffect(() => {
        const fetchPackagesAndSubscriptions = async () => {
            const packagesSnapshot = await getDocs(collection(db, 'subscriptionpacks'));
            const packagesData = packagesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setCreditPackages(packagesData);
        };

        fetchPackagesAndSubscriptions();
    }, []);

    // GET USER LOCATION BY IP TO DISPLAY USD PRICES OR EUR PRICES
    useEffect(() => {
        const fetchLocation = async () => {
            try {
                const response = await axios.get('https://ipinfo.io/json?token=6e7575329cf880');
                if (response.data && response.data.country) {
                    setIsUS(response.data.country === 'US');
                }
            } catch (error) {
                //console.error('Error fetching location:', error);
                setIsUS(true);
            }
        };

        fetchLocation();
    }, []);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(affiliateLink).then(() => {
            alert(t('mycredits.affiliate_link_copied'));
        });
    };

    // PROCEED TO CHECKOUT FUNCTION
    const handlePurchaseClick = async (packageId) => {
        const selectedPackage = creditPackages.find(pkg => pkg.id === packageId);

        if (selectedPackage && selectedPackage.stripe_link) {
            try {
                const response = await axios.post('/api/create-checkout-session', {
                    stripeLink: selectedPackage.stripe_link,
                    userId: currentUser.uid,
                    credits: selectedPackage.credits
                });
                //console.log('Checkout session response:', response.data);
                window.location.href = response.data.url;
            } catch (error) {
                //console.error('Error creating checkout session:', error);
            }
        } else {
            //console.error('Unknown package ID:', packageId);
        }
    };

    // HANDLE TERMS AND CONDITIONS CHECKBOX
    const handleTermsChange = (packageId, event) => {
        setTermsAccepted(prevState => ({
            ...prevState,
            [packageId]: event.target.checked,
        }));
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header2 />
            <div className="flex-1 p-8 text-center md:w-4/5 mx-auto pt-36">
                <h1 className="text-4xl font-bold text-center">{t('mycredits.title')}</h1>
                {loading ? (
                    <div className='min-h-[80vh] w-2/6  mx-auto flex items-center justify-center'>
                        <div className="border-4 border-dashed border-gray-300 rounded-full h-12 w-12 animate-spin"></div>
                    </div>
                ) : (
                    <>
                        <div className="credits-detail mb-8 text-center">
                            <h3 className="text-2xl">{t('mycredits.current_credits')}: {credits}</h3>
                        </div>
                        <div className="credits-section">
                            <h2 className="text-3xl font-bold text-center mb-4">{t('mycredits.credit_packs')}</h2>
                            <p className="text-xl text-center mb-8">{t('mycredits.credit_packs_subtitle')}</p>
                            <div className="flex justify-center items-center gap-6 flex-wrap">
                                {creditPackages
                                    .filter(pkg => (isUS && pkg.currency === 'USD') || (!isUS && pkg.currency === 'EUR'))
                                    .map((pkg) => (
                                        <div key={pkg.id} className={`package-card p-6 py-10 rounded-2xl shadow-md border hover:scale-105  border-gray-200 ${pkg.isfavorite ? 'bg-blue-600 text-white' : 'bg-white text-gray-900'} flex flex-col items-center w-80 transition-transform duration-300 hover:border-b-4 hover:border-b-gray-400`}>
                                            <div className="text-lg font-bold mb-4">{pkg.name}</div>
                                            <h1 className="text-4xl my-4 text-[#ff5722] space-y-2 text-center">
                                                {isUS ? `$${pkg.price}` : `€${pkg.price}`}
                                            </h1>
                                            <div className="text-lg mb-4 space-y-2 text-center">
                                                <span>{pkg.credits} {t('mycredits.credits')}</span> <br />
                                                <span>{t('mycredits.no_expiry')}</span>
                                            </div>
                                            <div className="terms-conditions mb-4 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    id={`terms-${pkg.id}`}
                                                    checked={termsAccepted[pkg.id] || false}
                                                    onChange={(event) => handleTermsChange(pkg.id, event)}
                                                    className="mr-2  rounded-full text-blue-600 focus:ring-blue-500"
                                                />
                                                <label htmlFor={`terms-${pkg.id}`} className="text-sm">
                                                    {t('mycredits.read_accept')} <a href="/legal" target="_blank" rel="noopener noreferrer" className="underline">{t('mycredits.accept_terms')}</a>
                                                </label>
                                            </div>
                                            <button
                                                className={`purchase-button py-2 px-4 rounded ${pkg.isfavorite ? 'bg-white text-blue-600' : 'bg-blue-600 text-white'} font-semibold`}

                                                disabled={!termsAccepted[pkg.id]}
                                            >
                                                {t('mycredits.purchase')} {isUS ? `$${pkg.price}` : `€${pkg.price}`}
                                            </button>
                                            <img src={stripeLogo} alt="Stripe Logo" className="stripe-logo mt-4 w-20" />
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default MyCreditsPage;
